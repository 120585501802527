import {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { t } from 'ttag';

// React version of Algolia's instantsearch library.
// Deprecated: package has moved to react-instantsearch (Todo)
import {
  InstantSearch,
  connectAutoComplete,
  SearchBox,
  Configure,
} from 'react-instantsearch-dom';

import classNames from 'classnames';
import Autocomplete from './Autocomplete';
import { DocsAssist } from 'components/docs-assist';
import { DocsAssistContext } from 'contexts/DocsAssistContext';
import { useDocsAssistData } from 'contexts/DocsAssistDataContext';
import { getSearchResults } from 'services/search';
import { LangContext } from 'contexts/LanguageContext';

import { Modal } from '@datarobot/design-system/modal';
import { Tabs, TabOptions } from '@datarobot/design-system/tabs';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { Badge } from '@datarobot/design-system/badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons/faWandMagicSparkles';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import './search.scss';

type RequestObj = {
  indexName: string;
  params: {
    query: string;
    page: number;
    filters: string;
    language: string;
  };
};

const customSearchClient = {
  search(requests: Array<RequestObj>) {
    if (requests.some(({ params: { query } }) => !!query)) {
      return getSearchResults(
        requests[0].params.query,
        requests[0].params.filters,
        requests[0].params.language,
        requests[0].params.page
      );
    }

    return null;
  },
};

const CustomAutocomplete = connectAutoComplete(Autocomplete);

const tabs: TabOptions[] = [
  {
    key: 'docsAssist',
    label: (
      <span className="tab-docs-assist-preview-badge">
        {t`DocsAssist`}
        <Badge
          blue
          outlined
          badgeClassNames="preview-badge"
        >{t`Preview`}</Badge>
      </span>
    ),
    icon: faWandMagicSparkles,
    testId: 'docs-assist-search-tab',
  },
  {
    key: 'classicSearch',
    label: t`Search`,
    icon: faSearch,
    testId: 'algolia-search-tab',
  },
];

const Search: FunctionComponent = () => {
  const docsAssistEnabled = useContext(DocsAssistContext);
  const docsAssistData = useDocsAssistData();
  const [showBot, setShowBot] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [itemType, setItemType] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { lang } = useContext(LangContext);
  const [activeTab, setActiveTab] = useState<TabOptions>(tabs[1]);

  const configureProps = itemType
    ? { filters: `itemType:${itemType}`, language: lang }
    : { language: lang };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('query');
    const searchBox = document.querySelector(
      '.ais-SearchBox-input'
    ) as HTMLInputElement;
    if (searchBox && query) {
      const event = new Event('input', { bubbles: true });
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window?.HTMLInputElement?.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(searchBox, query);
      setTimeout(() => {
        searchBox.dispatchEvent(event);
        const category = urlParams.get('category');
        if (category) {
          setItemType(category);
        }
      }, 0);
    }
  }, []);

  function doSearch(): void {
    setShowBot(false);
  }

  function doBot(): void {
    setShowBot(true);
  }

  function toggleModal(): void {
    setItemType(null);
    setSearchValue('');
    setModalOpen((prevState: boolean) => !prevState);
  }

  function doClassicSearch(value: string = ''): void {
    doSearch();
    setSearchValue(value);
    toggleModal();
  }

  function doDocsAssistSearch(): void {
    doBot();
    toggleModal();
  }

  function tabSelect(tab: TabOptions) {
    setActiveTab(tab);
    if (tab.key === 'classicSearch') {
      doSearch();
    } else if (tab.key === 'docsAssist') {
      doBot();
    }
  }
  const closeModal = () => {
    toggleModal();
    docsAssistData.resetData();
  };

  const classicSearch = useMemo(
    () => (
      <CustomAutocomplete
        onFilter={setItemType}
        itemType={itemType}
        searchValue={searchValue}
        onChange={(value: string) => setSearchValue(value)}
      />
    ),
    [itemType, searchValue]
  );

  // Ai Assist is not enabled; Original logic
  if (!docsAssistEnabled) {
    return (
      <div className="algolia-search" test-id="aloglia-search">
        <InstantSearch
          indexName="instant_search"
          searchClient={customSearchClient}
        >
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Configure {...configureProps} />
          <div className="dr-form-field search-input with-left-icon">
            <SearchBox
              autoFocus={false}
              searchAsYouType={false}
              translations={{ placeholder: t`Search documentation` }}
              onKeyDown={(e) => {
                e.stopPropagation();
                doClassicSearch(e.currentTarget.value);
              }}
            />
          </div>
          <Modal
            hideHeader
            hideFooter
            modalKey="algolia-search-modal"
            contentClassName="search-content"
            show={isModalOpen}
            onConfirmModal={() => toggleModal()}
          >
            <div test-id="algolia-search-modal">{classicSearch}</div>
          </Modal>
        </InstantSearch>
      </div>
    );
  }

  return (
    <div
      className="algolia-search doc-assist-main"
      test-id="search-with-aloglia-and-docsassist"
    >
      <Button
        accentType={ACCENT_TYPES.SECONDARY}
        onClick={() => {
          doDocsAssistSearch();
          setActiveTab(tabs[0]);
        }}
        leftIcon={faWandMagicSparkles}
        className="margin-right-4"
        testId="open-docs-assist-button"
      >
        <span className="ask-docs-assist-button">
          {t`Ask DocsAssist`}
          <Badge
            blue
            outlined
            badgeClassNames="preview-badge"
          >{t`Preview`}</Badge>
        </span>
      </Button>

      <Button
        accentType={ACCENT_TYPES.SECONDARY}
        onClick={() => {
          doClassicSearch();
          setActiveTab(tabs[1]);
        }}
        leftIcon={faSearch}
        testId="open-algolia-search-button"
      >
        {t`Search`}
      </Button>

      <Modal
        hideHeader
        hideFooter
        modalKey="algolia-search-modal"
        contentClassName="search-content"
        show={isModalOpen}
        onConfirmModal={closeModal}
      >
        <InstantSearch
          indexName="instant_search"
          searchClient={customSearchClient}
        >
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Configure {...configureProps} />
          <div
            className={classNames('search-assist-container', {
              'classic-search': !showBot,
              'docs-assist': showBot,
            })}
            test-id="search-with-aloglia-and-docsassist-tabs"
          >
            <div className="search-header">
              <span test-id="search-title">
                {showBot ? t`DocsAssist` : t`Search docs`}
              </span>
              <div className="header-buttons">
                <Tabs
                  options={tabs}
                  onSelect={tabSelect}
                  selectedKey={activeTab.key}
                  className="header-tab-buttons"
                />
                <Button
                  onClick={closeModal}
                  accentType={ACCENT_TYPES.GHOST}
                  aria-label={t`Close search modal`}
                  tooltipText={t`Close`}
                  className="header-close-search-modal"
                  testId="close-search-modal"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </div>
            </div>
            {showBot ? (
              <DocsAssist
                searchValue={searchValue}
                onChange={(value) => {
                  setSearchValue(value);
                }}
                onClearSearch={() => {
                  setSearchValue('');
                }}
              />
            ) : (
              classicSearch
            )}
          </div>
        </InstantSearch>
      </Modal>
    </div>
  );
};

export default Search;
